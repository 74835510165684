import React from 'react'

const ReadMeCamp = () => {
	return (
		<>
			<h4>Проект: АСУ для детских оздоровительных лагерей</h4>
			<p>
				<b>Описание</b>: административная панель для работы с инфраструктурой
				лагерей - создание, редактирование, просмотр, удаление лагерей. В
				разрезе лагеря - работа с информацией по сменам, отрядам, размещению,
				родителям и детям, путевкам, программам, продажам и т. д. (создание,
				редактирование, просмотр, удаление, связи между разными сущностями).
			</p>
			<p>
				<b>Используемые технологии</b>: vite, ant-design/icons, antd, axios,
				dayjs, react, react-router-dom, styled-components, zustand, typescript
			</p>
			<p>
				<b>Url</b>: нет возможности указать из за юридических соглашений с
				заказчиком.
			</p>
			<p>
				<b>PS</b>: Проект создан с нуля
			</p>
		</>
	)
}

export default ReadMeCamp
